import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from "react";
const context = createContext(null);
const { Provider } = context;
function ThemeProvider({
  children,
  theme,
  inFieldset,
  columnAlign,
  columnHorizontalPad
}) {
  const parentContext = useContext(context);
  const value = useMemo(
    () => ({
      theme: theme ?? (parentContext == null ? void 0 : parentContext.theme) ?? "light",
      inFieldset: inFieldset ?? (parentContext == null ? void 0 : parentContext.inFieldset) ?? false,
      columnAlign: columnAlign ?? (parentContext == null ? void 0 : parentContext.columnAlign) ?? "start",
      columnHorizontalPad: columnHorizontalPad ?? (parentContext == null ? void 0 : parentContext.columnHorizontalPad) ?? "md"
    }),
    [
      inFieldset,
      parentContext == null ? void 0 : parentContext.inFieldset,
      parentContext == null ? void 0 : parentContext.theme,
      theme,
      columnAlign,
      parentContext == null ? void 0 : parentContext.columnAlign,
      columnHorizontalPad,
      parentContext == null ? void 0 : parentContext.columnHorizontalPad
    ]
  );
  return /* @__PURE__ */ jsx(Provider, { value, children });
}
function useTheme() {
  const c = useContext(context);
  return (c == null ? void 0 : c.theme) ?? "light";
}
function useIsInFieldset() {
  const c = useContext(context);
  return (c == null ? void 0 : c.inFieldset) ?? false;
}
function useColumnAlign() {
  const c = useContext(context);
  return (c == null ? void 0 : c.columnAlign) ?? "start";
}
function useChildTheme(color, variant) {
  const theme = useTheme();
  return useMemo(() => {
    if (color !== "white" && ["solid", "outline"].includes(variant))
      return color;
    if (color === "white" && variant !== "transparent") return "light";
    return theme;
  }, [theme, color, variant]);
}
function useColumnHorizontalPad() {
  const c = useContext(context);
  return (c == null ? void 0 : c.columnHorizontalPad) ?? "md";
}
export {
  ThemeProvider,
  useChildTheme,
  useColumnAlign,
  useColumnHorizontalPad,
  useIsInFieldset,
  useTheme
};
